import React, { useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { getNotificationInfiniteQueryOptions } from '@query';

import { NOTIFICATION_STATUS } from '@api';

import { Actions } from '@ui/uikit/components/icons/mono';

import { useNavLayout } from './NavLayout';
import { Notifications } from './Notifications';

const NotificationsButton = () => {
  const { user } = useNavLayout();
  const [isOpen, setIsOpen] = useState(false);
  const { data: hasUnreadNotification } = useInfiniteQuery({
    // NOTE: the same query options because logic for marking
    // notifications is implemented for the key that used inside it
    ...getNotificationInfiniteQueryOptions(),
    select: (data) => data.pages?.[0]?.rows?.[0]?.status === NOTIFICATION_STATUS.NEW,
    enabled: !!user?.data.id,
  });

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="msq-btn msq-btn-icon-md msq-btn-ghost size-[2.25rem] text-base-text-primary md-msq:msq-btn-icon-lg"
      >
        <Actions className="msq-btn-icon-child-md" />
        {hasUnreadNotification && (
          <span className="size-2 md-msq:size-2.5 border-2 border-base-bg rounded-full bg-palette-pink-52 absolute top-0 right-0" />
        )}
      </button>

      <Notifications open={isOpen} onOpenChange={setIsOpen} />
    </>
  );
};

export default NotificationsButton;

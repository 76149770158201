import { useMemo, useRef } from 'react';
import { InView, useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';

import { useInfiniteQuery } from '@tanstack/react-query';

import { namedErrors } from '@shared/constants/errors';
import {
  getNotificationInfiniteQueryOptions,
  useDeleteNotificationMutate,
  useMarkAsReadMutate,
} from '@shared/hooks/query/notification';

import * as Dialog from '@radix-ui/react-dialog';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';
import { Sqr } from '@ui-uikit/lib/components/icons/poly';
import { cn, formatTimeAgo } from '@ui-uikit/lib/utils';

import ImageProxy from '../../ImageProxy/ImageProxy';

export const Notifications = ({ ...props }: Dialog.DialogProps) => {
  const {
    data: notifications,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    ...getNotificationInfiniteQueryOptions(),
    enabled: props.open,
  });
  const { mutateAsync: deleteNotification } = useDeleteNotificationMutate();
  const { mutateAsync: markAsRead } = useMarkAsReadMutate();
  const { ref } = useInView({
    onChange: (inView) => inView && !isLoading && !isFetchingNextPage && fetchNextPage(),
  });
  const readNotificationIdsCandidatesRef = useRef<number[]>([]);
  const batchReadExecutionTimeoutRef = useRef(-1);

  const allNotifications = useMemo(
    () => notifications?.pages.flatMap((page) => page.rows),
    [notifications],
  );

  const handleMarkAsReadList = async () => {
    try {
      await markAsRead({ type: 'all' });
    } catch (error) {
      toast.error(namedErrors.DEFAULT_RETRY);
    }
  };

  // batches read for multiple notifications to prevent a lot of request during fast scroll
  const addToReadCandidate = (id: number) => {
    window.clearTimeout(batchReadExecutionTimeoutRef.current);

    readNotificationIdsCandidatesRef.current.push(id);

    batchReadExecutionTimeoutRef.current = window.setTimeout(() => {
      const ids = readNotificationIdsCandidatesRef.current;

      readNotificationIdsCandidatesRef.current = [];

      window.setTimeout(async () => {
        try {
          await markAsRead({ type: 'ids', ids });
        } catch (error) {
          toast.error(namedErrors.DEFAULT_RETRY);
        }
      }, 1000); // should be 1500 but with batch timeout (500) it 1000
    }, 500);
  };

  const handleDeleteNotification = async (id: number) => {
    try {
      await deleteNotification({ type: 'item', id });
    } catch (error) {
      toast.error(namedErrors.DEFAULT_RETRY);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await deleteNotification({ type: 'all' });
    } catch (error) {
      toast.error(namedErrors.DEFAULT_RETRY);
    }
  };

  const isEmpty =
    !allNotifications?.length ||
    !allNotifications.filter((notification) => !notification.removed).length;
  const showNotifications = !isLoading && !isEmpty;
  const showSkeleton = isLoading || hasNextPage;
  const showEmpty = !hasNextPage && !isLoading && isEmpty;

  const notificationListClassName = 'flex flex-col -my-0.5 [&>*>*]:my-0.5';

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-animate msq-dialog-mobile-menu-m xl-msq:msq-dialog-mobile-menu-d"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className="msq-dialog-content sm-msq:msq-dialog-content-sm sm-msq:msq-dialog-content-right xl-msq:max-w-[25rem]"
          >
            <div className="msq-dialog-header">
              <Dialog.Title className="msq-dialog-title">Notifications</Dialog.Title>

              <Dialog.Close asChild>
                <button className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button">
                  <CrossLg className="msq-btn msq-btn-icon-child" />
                </button>
              </Dialog.Close>
            </div>
            <div className="msq-dialog-body flex flex-col xl-msq:pt-5">
              {showNotifications && (
                <>
                  <div className="flex items-center gap-4 xl-msq:gap-6 mb-4 xl-msq:mb-5 xl-msq:px-2 text-base-text-tertiary text-caption-sm-m xl-msq:text-caption-sm-d  transition-colors">
                    <button onClick={handleMarkAsReadList} className="hover:text-base-text-primary">
                      Mark All as Read
                    </button>
                    <button onClick={handleDeleteAll} className="hover:text-base-text-primary">
                      Delete all
                    </button>
                  </div>

                  <ul className={notificationListClassName}>
                    {allNotifications.map((notification) => {
                      const isRead = notification.status === 'READ';
                      const isRemoved = !!notification.removed;

                      return (
                        <InView
                          as="li"
                          onChange={(inView) => {
                            const isReadCandidate =
                              readNotificationIdsCandidatesRef.current.includes(notification.id);

                            if (!inView || isRead || isRemoved || isReadCandidate) {
                              return;
                            }

                            addToReadCandidate(notification.id);
                          }}
                          key={notification.id}
                          className={cn('overflow-hidden', {
                            'animate-remove-item': isRemoved,
                          })}
                        >
                          <div
                            className={cn(
                              'flex items-start gap-2 p-2 pb-3 xl-msq:p-3 w-full xl-msq:gap-3 rounded-lg transition-colors',
                              isRead ? 'bg-transparent' : 'bg-right-side-panel-notification-unread',
                            )}
                          >
                            {notification.icon ? (
                              <ImageProxy
                                width={24}
                                height={24}
                                className="rounded size-6"
                                src={notification.icon}
                                alt={notification.icon || 'Icon'}
                              />
                            ) : (
                              <Sqr className="icon size-6" />
                            )}

                            <div className="w-full">
                              {notification.title && (
                                <div className="mb-1 xl-msq:mb-2 flex flex-col gap-1 xl-msq:gap-2 pt-1 xl-msq:pt-0.5">
                                  <h3 className="text-caption-md-strong-m xl-msq:text-caption-md-strong-d">
                                    {notification.title}
                                  </h3>

                                  <p
                                    className="text-base-text-secondary text-body-xs-m xl-msq:text-body-xs-d break-words inner-html"
                                    dangerouslySetInnerHTML={{ __html: notification.message }}
                                  />
                                </div>
                              )}

                              {!notification.title && (
                                <p
                                  className="my-0.5 text-base-text-secondary text-body-sm-m xl-msq:mt-0 xl-msq:mb-1 xl-msq:text-body-sm-d break-words inner-html"
                                  dangerouslySetInnerHTML={{ __html: notification.message }}
                                />
                              )}

                              <div className="flex gap-4 text-caption-xs-m xl-msq:text-caption-xs-d text-base-text-tertiary">
                                <span className="flex-1">
                                  {formatTimeAgo(new Date(notification.timestamp))}
                                </span>

                                <button
                                  onClick={() => handleDeleteNotification(notification.id)}
                                  className="hover:text-base-text-primary"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </InView>
                      );
                    })}
                  </ul>
                </>
              )}

              {showEmpty && (
                <p className="m-auto text-center text-base-text-quaternary text-caption-sm-m sm:text-caption-sm-d">
                  Nothing has happened here yet
                </p>
              )}

              {showSkeleton && (
                <>
                  {isLoading && (
                    <div className="flex items-center gap-4 xl-msq:gap-6 mb-4 xl-msq:mb-5 xl-msq:px-2 text-caption-sm-m xl-msq:text-caption-sm-d">
                      <span className="msq-skeleton-text w-24" />
                      <span className="msq-skeleton-text w-[3.25rem]" />
                    </div>
                  )}
                  <ul
                    key={notifications?.pages.length}
                    ref={ref}
                    className={notificationListClassName}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <li key={i}>
                        <div className="flex gap-2 p-2 pb-3 xl-msq:p-3 rounded-lg">
                          <div className="msq-skeleton-block size-6 rounded flex-shrink-0" />
                          <div className="flex flex-col flex-1">
                            <div className="mb-1 xl-msq:mb-2 flex flex-col gap-1 xl-msq:gap-2">
                              <span className="msq-skeleton-text text-caption-md-strong-m pt-1 xl-msq:pt-0.5 xl-msq:text-caption-md-strong-d" />

                              <span className="msq-skeleton-text h-12 text-body-xs-m xl-msq:text-body-xs-d" />
                            </div>
                            <div className="flex justify-between text-caption-xs-m xl-msq:text-caption-xs-d">
                              <span className="msq-skeleton-text w-16" />
                              <span className="msq-skeleton-text w-9" />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
